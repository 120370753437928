import React from 'react';

import { Grid } from '@nestoca/ui';

import { EditableCell } from 'components/editable';
import { WHY_NOT_NESTO_LENDING } from 'constants/submission-notes';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionDetailsProps } from 'types/submission-notes';

import styles from './section-details.module.scss';

export const NestoLending = ({
    sectionCompleted,
    submissionNotes,
}: SectionDetailsProps) => {
    const { i18n } = useI18n();

    const { whyNotNestoLending } = submissionNotes?.customFields || {};

    return (
        <Grid className={styles.grid}>
            <EditableCell
                isEditing={!sectionCompleted}
                name="whyNotNestoLending"
                fieldType="select"
                label="whyNotNestoLending"
                options={WHY_NOT_NESTO_LENDING(i18n)}
                value={whyNotNestoLending}
            />
        </Grid>
    );
};
