import { MissingDocument } from 'components/submission-notes/sections/submit-submission-notes/errors/missing-document';
import { HelocProductType } from 'types/heloc';

import { IncomeEmployment, IncomeOther, MaritalStatus } from './applicant';

import type { ApplicationType } from './application';
import type { OtherProperty, SubjectProperty } from './property';

export type SubmissionNoteState = 'CREATED' | 'SUBMITTED' | 'REJECTED';

export type SubmissionNoteType = {
    readonly id: number;
    applicationId: number;
    createdAt: string;
    updatedAt: string;
    firstSubmittedAt: string | null;
    submittedAt: string | null;
    acceptedAt: string | null;
    rejectedAt: string | null;
    state: SubmissionNoteState;
    teamLeadSubmitted: boolean;
    acceptRejectComment: string;
    customFields: CustomFields;
    applicationFields: ApplicationFields;
    diff: Diff[];
    applicationFieldsSnapshot: ApplicationFields;
    applicationUpdatedAt: string;
};

export type ValidateSubmissionNoteDocumentType = {
    missingDocuments: MissingDocument[];
    valid: boolean;
};

type Diff = {
    type: string;
    path: string[];
    from: string;
    to: string;
};

export type CustomFields = {
    transaction: Transaction;
    incomes: {
        employments: IncomeEmployment[];
        others: IncomeOther[];
    };
    assets: CustomFieldsAssets;
    otherProperties: CustomFieldsOtherProperties;
    credit: {
        comments: string;
    };
    lawyer: Lawyer;
    mortgageProtectionInsurance: MortgageProtectionInsurance;
    importantDetails: string;
    whyNotNestoLending: string;
    helocAdditionalComments: string;
    bridgeLoan?: CustomFieldsBridgeLoan;
    purchaseWithImprovement?: CustomFieldsPurchaseWithImprovement;
};

export type CustomFieldsAssets = Record<
    string,
    { id: number; applicantId: number; comments: string }
>;

export type CustomFieldsOtherProperties = Record<
    string,
    { id: number; applicantId: number; comments: string }
>;

export type CustomFieldsBridgeLoan = {
    id: string | null;
    mortgageAmount: number | null;
    baseRate: number | null;
    expectedDateToBeSold: string | null;
    sellingPrice: number | null;
    mortgageBalance: number | null;
};

export type CustomFieldsPurchaseWithImprovement = {
    purchase_price: number | null;
    improvement_amount: number | null;
};

type Transaction = {
    appraisalAdditionalComments: string;
    maritalDetails: string;
    preApproval?: {
        ignorePreApproval: boolean;
    };
};

type Lawyer = {
    notary: string;
    discussedLegalFees: boolean;
};

type MortgageProtectionInsurance = {
    presentedToClient: string;
    explanationIfNo: string;
};

export type ApplicationFields = {
    applicationType: ApplicationType;
    transaction: ApplicationFieldsTransaction;
    subjectProperty: SubjectProperty;
    mortgage: Mortgage;
    product: Product;
    incomes: {
        employments: IncomeEmployment[];
        others: IncomeOther[];
    };
    assets: ApplicationFieldsAssets;
    OtherProperties: OtherProperty[];
    credit: {
        highestScore?: string;
    };
    whyNotNestoLendingOptions: Record<string, string>;
    applicationSubType?: string;
    heloc?: HelocProductType;
};

export type ApplicationFieldsAssets = Record<
    string,
    {
        id: number;
        applicantId: number;
        description: string;
        type: ApplicationFieldsAssetsType;
        isUsedAsDownPayment: boolean;
        downPaymentAmount: number;
        value: number;
    }
>;

export type ApplicationFieldsAssetsType =
    | 'SAVINGS'
    | 'TFSA'
    | 'RRSP'
    | 'NON_REGISTERED_INVESTMENTS'
    | 'GIFT'
    | 'VEHICLE'
    | 'PROPERTY'
    | 'HELOC'
    | 'OTHER';

type Mortgage = {
    purposeDescription: string;
    lenderName: string;
    term: string;
    type: string;
    frequency: string;
    amortizationYears: number;
    amortizationMonths: number;
    insurable: boolean;
    baseRate: number;
    buydownRate: number;
    bestRate: number;
    finalBPS: number;
    isBlendedRate?: boolean;
};
type ApplicationFieldsTransaction = {
    mortgageClosingDate: string;
    helocClosingDate: string;
    financialWaiverDate: boolean;
    gds: number;
    ltv: number;
    maritalStatus: MaritalStatus;
    mortgageAmount: number;
    tds: number;
    preApproval: TransactionPreApprovalType;
    closingDate: string;
};

type TransactionPreApprovalType = {
    goLiveDate: string;
    preApprovedDate: string;
    type?: string;
};

type Product = {
    name: string;
    family: string;
    insurable: boolean;
    insurance: string;
    investor: string;
};

export type SectionDetailsProps = {
    sectionCompleted: boolean;
    title: string;
    submissionNotes: SubmissionNoteType;
};

type AdvisorChecks = {
    advisorCheckOne: boolean;
    advisorCheckTwo: boolean;
    advisorCheckThree: boolean;
    advisorCheckFour: boolean;
    advisorCheckFive: boolean;
};

export type DefaultValues = CustomFields & AdvisorChecks;

export const SECTION = {
    ADVISOR_CHECKLIST: 'ADVISOR_CHECKLIST',
    TRANSACTION_DETAILS: 'TRANSACTION_DETAILS',
    SUBJECT_PROPERTY: 'SUBJECT_PROPERTY',
    MORTGAGE_REQUEST: 'MORTGAGE_REQUEST',
    CREDIT_PROFILE: 'CREDIT_PROFILE',
    HELOC_DETAILS: 'HELOC_DETAILS',
    MORTGAGE_PROTECTION_INSURANCE: 'MORTGAGE_PROTECTION_INSURANCE',
    LAWYER_LEGAL: 'LAWYER_LEGAL',
    WHY_NOT_NESTO_LENDING: 'WHY_NOT_NESTO_LENDING',
    INCOME: 'INCOME',
    ASSETS: 'ASSETS',
    OWNED_PROPERTIES: 'OWNED_PROPERTIES',
    IMPORTANT_ADDITIONAL_DETAILS: 'IMPORTANT_ADDITIONAL_DETAILS',
    SUBMIT_SUBMISSION_NOTES: 'SUBMIT_SUBMISSION_NOTES',
} as const;

export type SectionKey = keyof typeof SECTION;

export type SectionId = typeof SECTION[SectionKey];

export type SubmissionNotesMenuItemStatus = 'completed' | 'error';

export type SubmissionNotesMenuSections = {
    name: SectionId;
    label: string;
    subtitle?: string;
};
